<template>
	<div>
		<CCol md="12" class="mb-4">
			<CRow align-horizontal="end">
				<CButton
					class="transparent float-right btn-export-csv"
					color="tertiary"
					:disabled="isExporting"
					@click="exportUsers"
				>
					<FontAwesomeIcon
						:icon="['far', isExporting ? 'circle-notch' : 'file-export']"
						:spin="isExporting"
						class="icon-left"
					/>
					Export CSV
				</CButton>
				<router-link
					:to="{ name: 'UserCreate' }"
					class="link-button"
				>
					<CButton class="btn-new-user transparent ml-3" color="secondary">
						<CIcon class="icon-left" name="cil-plus" />
						Create user
					</CButton>
				</router-link>
			</CRow>
		</CCol>
		<CRow>
			<CCol class="d-flex align-items-start">
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						type="text"
						placeholder="Search by username, name, email, store name, or store ID"
					>
						<template #prepend-content>
							<button class="btn-search" type="submit">
								<FontAwesomeIcon :icon="['far', 'search']" />
							</button>
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					class="ml-3"
					data-id="filter-button"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="bg-gray-100 rounded-sm pt-3 px-3 mb-3"
						data-id="show-filter-box"
					>
						<CRow>
							<CCol md="3">
								<BaseDropDown
									:value="getSelectedValue(
										queryParams.role_id,
										userGroupDropdown,
									)"
									:options="userGroupDropdown"
									:allow-empty="false"
									:searchable="false"
									label-drop-down="Group name"
									label="name"
									class="select-custom"
									track-by="value"
									placeholder="All group"
									@input="handleGroup"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									v-model="status"
									:options="USER_STATUS_OPTIONS"
									:allow-empty="false"
									:searchable="false"
									label="name"
									label-drop-down="Status"
									track-by="value"
									class="select-custom"
									@input="handleSearch"
								/>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>
		<CRow>
			<CCol class="mb-4">
				<BaseSearchFormFooter
					:count="list.meta.total"
					append-text="lists found"
					@onReset="handleResetFilter"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: pagination.lastPage,
						activePage: pagination.currentPage
					}"
					:fields="USER_TABLE_FIELDS"
					:items="users"
					striped
					hover
					fixed
					clickable-rows
					link-to="UserEdit"
					class="table-custom table-custom-link"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t("global.searchNotFound", { field: "users" }) }}
							</p>
						</div>
					</template>
					<template #status="{item}">
						<CBadge
							class="badge-status"
							:color="USER_STATUSES[item].class"
						>
							{{ USER_STATUSES[item].title }}
						</CBadge>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {
	USER_STATUSES,
	USER_STATUS_OPTIONS,
	USER_TABLE_FIELDS,
} from '../enums/users';
import {
	cleanObject,
	hasSearchFilter,
	randomSearchString,
} from '../assets/js/helpers';
import { exportUserInfoAPI } from '../services/api/export.api';


export default {
	name: 'User',
	data() {
		return {
			USER_STATUS_OPTIONS,
			USER_TABLE_FIELDS,
			USER_STATUSES,
			isShowFilter: false,
			isExporting: false,
			queryParams: {
				q: this.$route.query.q || null,
				role_id: Number(this.$route.query.role_id) || null,
				is_active: (Number(this.$route.query.is_active) === 0 || Number(this.$route.query.is_active) === 1)
					? Number(this.$route.query.is_active)
					: null,
				page: Number(this.$route.query.page) || null,
				r: randomSearchString(),
			},
			group: 'All group',
		};
	},
	computed: {
		...mapState('users', {
			create: 'create',
			list: 'list',
		}),
		...mapState('roles', {
			roleList: 'list',
		}),
		...mapGetters({
			users: 'users/userList',
		}),

		isLoading() {
			return this.list.isLoading;
		},
		pagination() {
			return this.list.meta;
		},
		isCreateSuccess() {
			return this.create.isSuccess;
		},
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		userGroupDropdown() {
			const roles = this.roleList.data;

			return [
				{
					name: 'All group', value: null,
				},
				...roles.map((role) => ({
					name: role.name,
					value: role.id,
				})),
			];
		},
		status: {
			get() {
				return USER_STATUS_OPTIONS.find((option) => option.value === this.queryParams.is_active);
			},
			set(status) {
				this.queryParams.is_active = status.value;

				this.updateUrlParams();
			},
		},
	},
	created() {
		this.isShowFilter = this.hasFilter;
		this.getUsers(this.queryParams);
		this.getRoleList();
	},
	mounted() {
		if (this.isCreateSuccess) {
			this.showToast({
				content: this.$t('page.users.create.successMessage'),
				header: this.$t('page.users.create.successMessageTitle'),
				type: 'success',
			});

			setTimeout(() => this.clearError(), 3000);
		}
	},
	methods: {
		...mapActions({
			showToast: 'toast/showToast',
			getUsers: 'users/getUsers',
			clearError: 'users/clearError',
			getRoleList: 'roles/getRoleList',
		}),
		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		updateUrlParams() {
			const query = cleanObject(JSON.parse(JSON.stringify(this.queryParams)));
			this.$router.push({ query }).catch(() => {});
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleGroup(role) {
			this.queryParams.page = null;
			this.queryParams.role_id = role.value;
			this.updateUrlParams();
		},
		handleResetFilter() {
			this.queryParams = {
				q: null,
				role_id: null,
				is_active: null,
				page: null,
				r: null,
			};

			this.updateUrlParams();
		},
		getSelectedValue(selectedValue = null, options = []) {
			const optionValues = Object.values(options);
			const found = ({ value }) => value === selectedValue;

			return (
				optionValues.find(found) || {
					value: null,
					label: null,
				}
			);
		},
		async exportUsers() {
			this.exportWrapper(exportUserInfoAPI);
		},
		async exportWrapper(exportAPI) {
			this.isExporting = true;
			try {
				await exportAPI({
					q: this.queryParams.q,
					role_id: this.queryParams.role_id,
					is_active: this.queryParams.is_active,
				});
			} finally {
				this.isExporting = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-search {
		background: transparent;
		box-shadow: none;
		border: none;
		padding: 0;
	}
</style>
